// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/SignIn.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/SignIn.tsx");
  import.meta.hot.lastModified = "1721465185734.1055";
}
// REMIX HMR END

import { useState } from "react";
import userData from "./Users.json";
export default function SignIn({
  setStatus
}) {
  _s();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const handleSignIn = () => {
    const user = userData.users.find(user => user.username === username && user.password === password);
    if (user) {
      setStatus(true);
      setError('');
    } else {
      setError('Invalid username or password');
    }
  };
  return <div className="sign-in">
            <div className="header">
                <h1>EVA</h1>
            </div>
            <div className="body">
                <div className="holder">
                    <div className="container">
                        <h2>SIGN IN</h2>
                        <div className="input">
                            <label>Username</label>
                            <input type='text' onChange={e => setUsername(e.target.value)} autoFocus />
                        </div>
                        <div className="input">
                            <label>Password</label>
                            <input type='password' onChange={e => setPassword(e.target.value)} />
                        </div>
                    
                        <button onClick={handleSignIn}>SIGN IN</button>
                    </div> 
                    {error != '' ? <p style={{
          color: 'red'
        }}>{error}</p> : <></>}
                </div>
            </div>
        </div>;
}
_s(SignIn, "lABZ+K3zbnEaFQvlduiFXLNOf5A=");
_c = SignIn;
var _c;
$RefreshReg$(_c, "SignIn");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;