{
    "users": [
      {
        "username": "marija.absec@gmail.com",
        "password": "qweasdqwe"
      },
      {
        "username": "matej.zorec@gmail.com",
        "password": "qweasdqwe"
      },
      {
        "username": "matija.marolt@fri.uni-lj.si",
        "password": "qweasdqwe"
      }    ]
  }
  
