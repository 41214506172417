// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/Eva.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/Eva.tsx");
  import.meta.hot.lastModified = "1722340116000";
}
// REMIX HMR END

import { useEffect, useState } from "react";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
export default function Eva() {
  _s();
  const port = 8080;
  const address = `https://eva.musiclab.si`;
  const [video, setVideo] = useState(0);
  let videos = [{
    id: -1,
    value: 'Upload my video'
  }, {
    id: 0,
    value: 'Video 1',
    src: '/resources/Video(1).mp4',
    b_url: '1.mp4'
  }, {
    id: 1,
    value: 'Video 2',
    src: '/resources/Video(2).mp4',
    b_url: '1.mp4'
  }, {
    id: 2,
    value: 'Video 3',
    src: '/resources/Video(3).mp4',
    b_url: '1.mp4'
  }, {
    id: 3,
    value: 'Video 4',
    src: '/resources/Video(4).mp4',
    b_url: '1.mp4'
  }, {
    id: 4,
    value: 'Video 5',
    src: '/resources/Video(5).mp4',
    b_url: '1.mp4'
  }, {
    id: 5,
    value: 'Video 6',
    src: '/resources/Video(6).mp4',
    b_url: '1.mp4'
  }, {
    id: 6,
    value: 'Video 7',
    src: '/resources/Video(7).mp4',
    b_url: '1.mp4'
  }, {
    id: 7,
    value: 'Video 8',
    src: '/resources/Video(8).mp4',
    b_url: '1.mp4'
  }];
  let encodeBitrate = [{
    id: 0,
    val: '800k',
    value: '800'
  }, {
    id: 1,
    val: '1000k',
    value: '1000'
  }, {
    id: 2,
    val: '1200k',
    value: '1200'
  }, {
    id: 3,
    val: '1400k',
    value: '1400'
  }, {
    id: 4,
    val: '1600k',
    value: '1600'
  }, {
    id: 5,
    val: '1800k',
    value: '1800'
  }, {
    id: 6,
    val: '2000k',
    value: '2000'
  }, {
    id: 7,
    val: '2200k',
    value: '2200'
  }, {
    id: 8,
    val: '2400k',
    value: '2400'
  }, {
    id: 9,
    val: '2600k',
    value: '2600'
  }, {
    id: 10,
    val: '2800k',
    value: '2800'
  }, {
    id: 11,
    val: '3000k',
    value: '3000'
  }];
  const [encBitr, setEncBitr] = useState(4);
  const [uplVideo, setUplVideo] = useState({
    name: '',
    src: ''
  });
  const [upload, setUpload] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [uploadFile, setUploadFile] = useState(null);
  const [resultVideo, setResultVideo] = useState('');
  const [processing, setProcessing] = useState(false);
  const [calculating, setCalculating] = useState(false);
  const [processingError, setProcessingError] = useState('');
  const [processedBitrate, setProcessedBitrate] = useState('');
  const [processedQuality, setProcessedQuality] = useState({
    mse: null,
    gmsd: null,
    psnr: null,
    ssim: null,
    vmaf: null
  });
  const [taskId, setTaskId] = useState(-1);
  function handleChange(event) {
    setVideo(Number(event.target.value));
    setUpload(video < 0);
  }
  const handleFile = e => {
    let f = e.target.files?.[0];
    if (!f) {
      setUploadFile(null);
      return;
    } else if (!f.type.startsWith('video/')) {
      setUploadError('Please upload a valid video file.');
      setUploadFile(null);
      return;
    }
    const videoSize = Math.round(f.size / (1024 * 1024));
    const MAX_SIZE = 200;
    if (videoSize > MAX_SIZE) {
      setUploadError('Video duration exceeds the maximum allowed (30 seconds for UHD).');
      setUploadFile(null);
      return;
    } else {
      setUploadFile(f);
      setUploadError(`Ready for upload.`);
    }
  };
  const handleUpload = () => {
    if (!uploadFile) {
      setUploadError("Select a file for upload!");
      return;
    }
    let form = new FormData();
    form.append("video", uploadFile);
    axios.post(`${address}/api/`, form, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(async res => {
      console.log("Successful upload");
      await setUploadError("The upload was successful");
      const filename = res.data.split("filename=")[1];
      setUplVideo({
        name: filename,
        src: URL.createObjectURL(uploadFile)
      });
      console.log(uploadFile.name);
    });
  };
  const [check1, setCheck1] = useState(true);
  const [range1, setRange1] = useState(10);
  const [range2, setRange2] = useState(0);
  let labels1 = ['10%', '20%', '30%', '40%'];
  let labels2 = ['Low', 'Medium', 'High', 'Extreme'];
  const handleRange = (e, setRange) => {
    let percent = (Number(e.target.value) - Number(e.target.min)) / (Number(e.target.max) - Number(e.target.min)) * 100;
    e.target.style.background = `linear-gradient(to right, blue ${percent}%, #666 ${percent}%)`;
    setRange(Number(e.target.value));
  };
  const handleResults = async (i, id, timeout, proc, calc) => {
    if (i < 500) {
      if (!proc && !calc) {
        console.log('not processing and not calculating !!');
      }
      await new Promise(resolve => setTimeout(resolve, timeout));
      console.log(`fetching results i=${i}, ${proc ? 'True' : 'False'}, ${calc ? 'True' : 'False'}`);
      try {
        const response = await axios.get(`${address}/api/result/id=${id}`);
        if (response.data.status == 'done') {
          console.log('done response');
          if (processing) {
            console.log('still processing, so have to do something about it');
            setResultVideo(`${address}/api${response.data.url}`);
            setProcessing(false);
            proc = false;
          }
          setProcessedBitrate(response.data.bitrate);
          setProcessedQuality(JSON.parse(response.data.quality));
          console.log(response.data.quality);
          console.log(response.data.bitrate);
          setCalculating(false);
          calc = false;
        } else {
          if (response.data.status == 'calculating quality' && proc) {
            console.log('calculating quality and processing = response');
            setResultVideo(`${address}/api${response.data.url}`);
            setCalculating(true);
            setProcessing(false);
            proc = false;
            calc = true;
            await handleResults(i + 1, id, 5000, proc, calc);
          } else if (response.data.status == 'error') {
            console.log('error in response');
            setProcessing(false);
            setCalculating(false);
            proc = false;
            calc = false;
            setProcessingError('An error occurred while processing the video.');
          } else {
            console.log('else response');
            await handleResults(i + 1, id, 3000, proc, calc);
          }
        }
      } catch (error) {
        console.error('Error fetching results:', error);
        setProcessing(false);
        setCalculating(false);
        setProcessingError('An error occurred while fetching the results.');
      }
    }
  };
  const submit = () => {
    setProcessing(true);
    setProcessedBitrate('');
    setProcessedQuality({
      mse: null,
      gmsd: null,
      psnr: null,
      ssim: null,
      vmaf: null
    });
    setResultVideo('');
    setProcessingError('');
    setCalculating(false);
    console.log('submitting video');
    const json_data = JSON.stringify({
      video: video != -1 ? videos.filter(i => i.id == video)[0] : {
        id: -1,
        value: uplVideo.name
      },
      filter: check1 ? 'bitrate' : 'quality',
      filter_value: check1 ? range1 / 10 : range2,
      encode_bitrate: encodeBitrate[encBitr]
    });
    axios.post(`${address}/api/submit`, json_data, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(async res => {
      if (res.data.message != '') {
        console.log('on submit response was not empty, processing to false');
        setProcessing(false);
        if (res.data.task_id == 0 && res.data.message == 'Preprocess') {
          setResultVideo(res.data.url);
          setProcessedQuality(res.data.quality);
          setProcessedBitrate(res.data.bitrate);
          setProcessingError('');
        } else {
          setProcessingError(res.data.message);
        }
      } else {
        setTaskId(res.data.task_id);
        await new Promise(resolve => setTimeout(resolve, 20000));
        handleResults(1, res.data.task_id, 3000, true, false);
      }
    });
  };
  useEffect(() => {
    if (video === -1) {
      setUpload(true);
    }
  }, [video]);
  useEffect(() => {}, [uploadError]);
  return <div className="eva">
        <div className='header'>
            <h1>EVA</h1>
        </div>
        <div className="body">
            <div className="videoholder flex">
                {!upload || uplVideo.src != '' ? <video key={video != -1 ? videos[video].value : uplVideo.name} controls autoPlay muted loop>
                    <source src={video != -1 ? videos.filter(it => it.id == video)[0].src : uplVideo.src} /></video> : <div className="videofill"></div>}
                {processing ? <div className="videofill">
                <CircularProgress />
                <p className="message">Processing the video, please wait. This might take a few minutes.</p>
                <button onClick={() => {
            setProcessing(false);
            setCalculating(false);
            setProcessingError('');
            setProcessedQuality({
              mse: null,
              gmsd: null,
              psnr: null,
              ssim: null,
              vmaf: null
            });
            setProcessedBitrate('');
            axios.get(`${address}/api/stop/id=${taskId}`).then(res => {
              console.log(res.data);
            });
          }}>Stop processing</button>
                </div> : resultVideo != '' ? <video key={resultVideo} controls autoPlay muted loop>
                    <source src={resultVideo} />
                    Video: {resultVideo}
                </video> : <div className="videofill"><p className="error">{processingError}</p><p>{resultVideo}</p></div>}
            </div>
            <div className="controls flex">
                <div className="settings flex">
                    <div className="choosevideo flex">
                        <span>Choose Video</span>
                        {!upload ? <select value={video} onChange={handleChange}>
                        {videos.map(item => <option key={item.id} value={item.id}>{item.value}</option>)}
                        </select> : <div className="uploadvideo">
                            <input type='file' name='video' onChange={e => handleFile(e)} />
                            <div className="buttons flex">
                                <button onClick={handleUpload}>Upload file</button>
                                <button className='back' onClick={() => {
                  setUpload(false);
                  setUploadFile(null);
                  setUploadError('');
                  setVideo(0);
                  setUplVideo({
                    name: '',
                    src: ''
                  });
                }}>Back</button>
                            </div>
                            {uploadError != '' ? uploadFile != null ? <p style={{
                color: 'green'
              }}>{uploadError}</p> : <p style={{
                color: 'red'
              }}>{uploadError}</p> : <></>}
                        </div>}
                    </div>
                    
                    <div className="filters inline">
                        <div className="slider flex">
                            <span>Bit-rate: </span>
                            <input type='checkbox' checked={check1} onChange={e => setCheck1(!check1)} />
                            <div className="range">
                                <label>Bit-rate</label>
                                <input type="range" value={range1} min='10' max='40' step='10' onChange={e => handleRange(e, setRange1)} disabled={!check1} />
                                <div className="range-labels flex">
                                    {labels1.map(i => <label key={i}>{i}</label>)}
                                </div>
                            </div>
                            
                            
                        </div>
                        <div className="slider flex">
                            <span>Details: </span>
                            <input type='checkbox' checked={!check1} onChange={e => setCheck1(!check1)} />
                            <div className="range">
                                <label>Enhancement</label>
                                <input type="range" value={range2} min='0' max='3' step='1' onChange={e => handleRange(e, setRange2)} disabled={check1} />
                                <div className="range-labels flex ">
                                    {labels2.map(i => <label key={i}>{i}</label>)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bitrate-combo flex">
                        <span>Bit-rate</span> /* Change name!!! */
                        <select onChange={e => setEncBitr(Number(e.target.value))} defaultValue={4}>
                            {encodeBitrate.map(item => <option key={item.id} value={item.id}>{item.val}</option>)}
                        </select>                    
                    </div>
                </div>
                <div className="buttonborder flex">
                    <div className="start">
                    <button onClick={!processing ? submit : () => {}}>
                        <div className="light" style={{
                backgroundColor: processing ? 'orange' : 'green'
              }} /><span>START</span>
                    </button>
                </div>
                </div>
                
                <div className="parameters flex">
                    <div className="params1"> 
                        <p>Bit-rate: </p>
                        <p>Errors (MSE): </p>
                        <p>Quality (GMSD): </p>
                        <p>Quality (PSNR): </p>
                        <p>Quality (SSIM): </p>
                        <p>Quality (VMAF): </p>
                    </div>
                    {!calculating ? <div className="params2">
                                <p>{processedBitrate}</p>
                                <p>{processedQuality.mse != null ? processedQuality.mse : ' '}</p>
                                <p>{processedQuality.gmsd != null ? processedQuality.gmsd : ' '}</p>
                                <p>{processedQuality.psnr != null ? processedQuality.psnr : ' '}</p>
                                <p>{processedQuality.ssim != null ? processedQuality.ssim : ' '}</p>
                                <p>{processedQuality.vmaf != null ? processedQuality.vmaf : ' '}</p>
                            </div> : <div className="params2 center">
                                <CircularProgress />
                                <p className="message">Calculating metrics</p>    
                            </div>}
                    
                </div>
            </div>
        </div>
      </div>;
}
_s(Eva, "fnY8SVKhk4VP+sjogVSn1cw/WBA=");
_c = Eva;
var _c;
$RefreshReg$(_c, "Eva");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;